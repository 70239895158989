/**
 * Application entry point
 */

// Load application styles
import '../styles/scss/variables.scss';
import '../styles/scss/main.scss';
// import { number } from './math'
console.log('styles loaded!')

// ================================
// START YOUR APP HERE
// ================================